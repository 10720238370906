import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../layouts/Layout"
import PageFooter from "../components/PageFooter"
import SiteMetadata from "../components/SiteMetadata"

const AboutPage = ({ data }) => (
  <Layout>
    <SiteMetadata
      title="Pri Bellafronte - Designer de marcas e logos | Sobre mim"
      description="Meu nome é Priscilla Bellafronte, sou designer gráfico e essa página contra um pouco sobre mim"
    />

    <div>
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-1/5 pb-8 md:pb-0">
            <GatsbyImage
              image={data.author.childImageSharp.gatsbyImageData}
              alt="Pri Bellafronte"
              className="rounded-full shadow-md"
            />
          </div>

          <div className="w-full md:w-1/2 xl:w-4/5 md:pl-12">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Sobre mim
            </h1>

            <div className="mt-4 leading-loose">
              Olá! Meu nome é Pri Bellafronte. Sou Designer Gráfico especialista
              no desenvolvimento de marcas e logotipos que vão muito além do
              visual. Busco transmitir a essência, a ideia e a história. E
              acredito que para criar experiências, precisamos buscar nas raízes
              de cada projeto a sua real essência.
              <br />
              <br />
              Design vai muito além da estética, é a ciência da inovação que
              busca comunicar e integrar pessoas, pensando na função e na
              essência que compõe a experiência.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="py-12 px-4 lg:py-16">
      <PageFooter />
    </div>
  </Layout>
)

export default AboutPage

export const query = graphql`
  {
    author: file(relativePath: { eq: "perfil.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 600
          quality: 85
          layout: CONSTRAINED
        )
      }
    }
  }
`
